import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {
  ArrowBackRounded,
  CancelOutlined,
  CheckCircleOutlineOutlined,
  RadioButtonUncheckedOutlined,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../services/api";
import { useRecoilValue } from "recoil";
import {
  CircularProgress,
  Container,
  Grid,
  ListSubheader,
} from "@mui/material";
import { CirclePulse } from "../CirclePulse/CirclePulse";
import { OrderStatusStepInterface } from "./OrderDetailsTypes";
import { CirclePulseWrapper, IconStepWrapper } from "./OrderDetailsStyles";
import {
  deliveryWayMap,
  deliveryWayPickupId,
  orderStatusCanceled,
  orderStatusDeliveryMap,
  orderStatusPickupMap,
} from "../../services/static";
import { numberToBRL } from "../../utils/formatters";
import useDevice from "../../hooks/useDevice";
import { homeDataState } from "../../state/atoms/homeData";
import { FilterPaymentMethodAPI } from "../../interfaces/filter";
import useAccount from "../../hooks/useAccount";
import { getFullAddress } from "../../helpers/address";
import { CartInterfaceAPI } from "../../interfaces/cart";
import { TransitionUp } from "../Transition/Transition";

export default function OrderDetails() {
  const { id } = useParams();
  const account = useAccount();
  const homeData = useRecoilValue(homeDataState);
  const [order, setOrder] = useState<CartInterfaceAPI>();
  const [paymentMethods, setPaymentMethods] = useState<
    FilterPaymentMethodAPI[]
  >([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isMobile } = useDevice();

  const paymentMethodLabel = paymentMethods.find(
    (p) => p.value === order?.payment_method_id
  )?.label;

  const orderIsPickup = order?.delivery_way === deliveryWayPickupId;

  const handleClose = () => {
    navigate(`../meus-pedidos`);
  };

  const fetchOrderDetails = (showLoading = false) => {
    setLoading(showLoading);

    if (id) {
      api
        .get<CartInterfaceAPI>(`/orders/${id}/show`, {
          headers: { Authorization: `Bearer ${account.token}` },
        })
        .then((resp) => {
          setOrder(resp.data);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchOrderDetails(true);
    const timer = setInterval(() => fetchOrderDetails(false), 10000);
    return () => clearTimeout(timer);
  }, [id]);

  useEffect(() => {
    api
      .get<FilterPaymentMethodAPI[]>(
        `/filter/payment-methods/${homeData.company.id}`
      )
      .then((resp) => {
        setPaymentMethods(resp.data);
      });
  }, []);

  const OrderStatusStep = ({
    icon,
    title,
    subtitle,
  }: OrderStatusStepInterface) => (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid
        item
        flex={0}
        paddingRight="15px"
        display="flex"
        alignItems="center"
      >
        {icon}
      </Grid>
      <Grid
        item
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        flexDirection="column"
        flex={1}
      >
        <Typography fontWeight="bold">{title}</Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Grid>
    </Grid>
  );

  const renderOrderStepCanceled = () => (
    <ListItem>
      <OrderStatusStep
        title="Pedido cancelado"
        subtitle="Infelizmente seu pedido foi cancelado."
        icon={
          <IconStepWrapper color="error">
            <CancelOutlined fontSize="inherit" />
          </IconStepWrapper>
        }
      />
    </ListItem>
  );

  const renderOrderSteps = () => {
    let currStepFlag = false;
    const isCanceled = order?.order_status_id === orderStatusCanceled.id;

    if (isCanceled) {
      return renderOrderStepCanceled();
    }

    return (orderIsPickup ? orderStatusPickupMap : orderStatusDeliveryMap).map(
      (status) => {
        const isCurrStep = status.id === order?.order_status_id;

        if (isCurrStep) {
          currStepFlag = true;
        }

        const icon = isCurrStep ? (
          <CirclePulseWrapper>
            <CirclePulse />
          </CirclePulseWrapper>
        ) : (
          <IconStepWrapper color={currStepFlag ? "tertiary" : "primary"}>
            {currStepFlag ? (
              <RadioButtonUncheckedOutlined fontSize="inherit" />
            ) : (
              <CheckCircleOutlineOutlined fontSize="inherit" />
            )}
          </IconStepWrapper>
        );

        return (
          <ListItem key={status.id}>
            <OrderStatusStep
              title={status.title}
              subtitle={status.subtitle}
              icon={icon}
            />
          </ListItem>
        );
      }
    );
  };

  return (
    <Dialog fullScreen open={!!id} TransitionComponent={TransitionUp}>
      <AppBar sx={{ position: "sticky" }}>
        <Container maxWidth={isMobile ? false : "lg"} disableGutters>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              size="large"
              aria-label="close"
            >
              <ArrowBackRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {order?.name ?? "Detalhes do pedido"}
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      {loading ? (
        <Grid container justifyContent="center" marginTop="25vh">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          {/* {order?.payment_method_id === PIX_METHOD_CODE && order.total && (
            <List subheader={<ListSubheader>Pagamento via PIX</ListSubheader>}>
              <ListItem>
                <PixPayment
                  payment_pix_code={order.payment_pix_code}
                  payment_pix_expiry_date={order.payment_pix_expiry_date}
                  payment_status={order.payment_status}
                />
              </ListItem>
            </List>
          )} */}
          <List subheader={<ListSubheader>Andamento do pedido</ListSubheader>}>
            {renderOrderSteps()}
          </List>
          <List subheader={<ListSubheader>Detalhes do pedido</ListSubheader>}>
            <ListItem>
              <div>
                <div>
                  <b>Produtos</b>
                </div>
                {order?.products.map((product) => (
                  <div key={product.id}>
                    {product.name} — {numberToBRL(product.price)}
                    {/* {product.kit &&
                      product.kit_products.map((p) => (
                        <Typography
                          color={theme.tertiary}
                          variant="overline"
                          display="block"
                          marginLeft={1}
                        >
                          • {p.name}
                        </Typography>
                      ))} */}
                  </div>
                ))}
              </div>
            </ListItem>
            <ListItem>
              <div>
                <div>
                  <b>Observações</b>
                </div>
                <div>{order?.observation ?? "—"}</div>
              </div>
            </ListItem>
            {order?.scheduled_at && (
              <ListItem>
                <div>
                  <div>
                    <b>Agendamento do pedido</b>
                  </div>
                  <div>{order?.scheduled_at}</div>
                </div>
              </ListItem>
            )}
            <ListItem>
              <div>
                <div>
                  <b>{deliveryWayMap[orderIsPickup ? 0 : 1].label}</b>
                </div>
                <div>
                  {orderIsPickup
                    ? homeData.company.address
                    : getFullAddress(order?.address!)}
                </div>
              </div>
            </ListItem>
            <ListItem>
              <div>
                <div>
                  <b>Informações do pagamento</b>
                </div>
                <div>Forma de pagamento — {paymentMethodLabel}</div>{" "}
                <div>Subtotal de produtos — {numberToBRL(order?.subtotal)}</div>
                {!!order?.increase && (
                  <div>Acréscimo — {numberToBRL(order?.increase)}</div>
                )}
                {!!order?.increase_tax_method && (
                  <div>
                    Acréscimo {paymentMethodLabel} —{" "}
                    {numberToBRL(order?.increase_tax_method)}
                  </div>
                )}
                {!!order?.discount && (
                  <div>Desconto — {numberToBRL(order?.discount)}</div>
                )}
                <div>Taxa de entrega — {numberToBRL(order?.delivery_tax)}</div>
                <div>
                  Valor total — <b>{numberToBRL(order?.total)}</b>
                </div>
              </div>
            </ListItem>
            <ListItem>
              <Typography variant="caption">
                Pedido realizado em {order?.created_at}
              </Typography>
            </ListItem>
          </List>
        </>
      )}
    </Dialog>
  );
}
