import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { useRecoilState } from "recoil";
import { dialogProviderState } from "../../state/atoms/dialog";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DialogProvider = () => {
  const [providerState, setProviderState] = useRecoilState(dialogProviderState);

  const handleClose = () => {
    setProviderState({
      open: false,
      title: "",
      description: "",
    });
  };

  return (
    <Dialog
      open={providerState.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      style={{ zIndex: 1400 }}
    >
      <DialogTitle>{providerState.title}</DialogTitle>

      {providerState.description && (
        <DialogContent>
          <DialogContentText>
            <div
              dangerouslySetInnerHTML={{ __html: providerState.description }}
            />
          </DialogContentText>
        </DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleClose}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
};
