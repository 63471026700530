import { Alert, Container, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProductCard from "../components/ProductCard/ProductCard";
import SectionHeader from "../components/SectionHeader/SectionHeader";
import { ProductInterfaceAPI } from "../interfaces/product";
import { removeStringAccents } from "../utils/formatters";
import { Link } from "react-scroll";
import CartResume from "../components/CartResume/CartResume";
import { useRecoilValue } from "recoil";
import { homeDataState } from "../state/atoms/homeData";
import MyOrdersResume from "../components/MyOrdersResume/MyOrdersResume";
import {
  ContainerStyled,
  Cover,
  Header,
  PageWrapper,
  Profile,
  TabsWrapper,
} from "./HomeStyles";
import { HomeDataInterface } from "../interfaces/home";
import { homeDataIsOpenNow } from "../state/selectors/homeData";
import useCompanyFeatures from "../hooks/companyPlan";
import { WhatsappFabButton } from "../components/WhatsappFabButton/WhatsappFabButton";
import useAccount from "../hooks/useAccount";
import useCart from "../hooks/useCart";
import { getShopContext } from "../utils/setup";
import { EventAvailableRounded } from "@mui/icons-material";

export default function Home() {
  const navigate = useNavigate();
  const account = useAccount();
  const homeData = useRecoilValue(homeDataState);
  const isOpenNow = useRecoilValue(homeDataIsOpenNow);
  const { hasFeature } = useCompanyFeatures();
  const { data: cart } = useCart();
  const [currTab, setCurrTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [productSelected, setProductSelected] = useState<ProductInterfaceAPI>(
    {} as ProductInterfaceAPI
  );

  const checkOrdersPermission = () => {
    const shopContext = getShopContext();

    if (shopContext === "root") return hasFeature("root-orders");
    if (shopContext === "delivery") return hasFeature("delivery-orders");
    if (shopContext === "cardapio") return hasFeature("cardapio-orders");

    return false;
  };

  const handleOpenPreview = (product: ProductInterfaceAPI) => {
    setProductSelected(product);
    navigate(`./produto/${product.id}`);
  };

  const applySearchQuery = (products: ProductInterfaceAPI[]) => {
    if (searchQuery) {
      const simpleQuery = removeStringAccents(searchQuery).toLowerCase();
      return products.filter((product) => {
        const name = removeStringAccents(product.name).toLocaleLowerCase();
        const full_description = removeStringAccents(
          product.full_description
        ).toLocaleLowerCase();

        return (
          name.includes(simpleQuery) || full_description.includes(simpleQuery)
        );
      });
    }

    return products;
  };

  const renderOpeningHour = (
    dayName: string,
    dayKey: keyof HomeDataInterface["company"]["opening_hours"]
  ) => {
    const { initial, final } = homeData.company.opening_hours[dayKey];

    return (
      <p>
        {dayName} —{" "}
        {initial === null && final === null
          ? "fechado"
          : `${initial} às ${final}`}
      </p>
    );
  };

  return (
    <PageWrapper>
      <ContainerStyled>
        <Cover background={homeData.company.cover_photo_url}>
          <Profile background={homeData.company.profile_photo_url} />
        </Cover>
      </ContainerStyled>
      <Container>
        <Header>
          <h1>{homeData.company.name}</h1>
          {homeData.company.description && (
            <p>{homeData.company.description}</p>
          )}
        </Header>
        {!isOpenNow && hasFeature("scheduled-orders") && (
          <Alert
            variant="standard"
            severity="warning"
            style={{ marginTop: "35px", alignItems: "center" }}
            icon={<EventAvailableRounded />}
          >
            <Typography>
              A loja está fechada no momento, mas você pode{" "}
              <b>agendar um pedido</b> para retirada ou entrega em outro
              horário.
            </Typography>
          </Alert>
        )}
        {!isOpenNow && !hasFeature("scheduled-orders") && (
          <Alert
            variant="standard"
            severity="error"
            style={{ marginTop: "35px" }}
          >
            <Typography marginBottom={1.5}>
              <b>Loja fechada no momento.</b>
              <br />
              Verifique o horário de funcionamento:
            </Typography>
            {renderOpeningHour("Segunda-feira", "monday")}
            {renderOpeningHour("Terça-feira", "tuesday")}
            {renderOpeningHour("Quarta-feira", "wednesday")}
            {renderOpeningHour("Quinta-feira", "thursday")}
            {renderOpeningHour("Sexta-feira", "friday")}
            {renderOpeningHour("Sábado", "saturday")}
            {renderOpeningHour("Domingo", "sunday")}
          </Alert>
        )}
        <TabsWrapper>
          <Tabs value={currTab} variant="scrollable" scrollButtons="auto">
            {homeData.categories
              .filter((c) => c.products.length > 0)
              .map((category, index) => (
                <Link
                  key={`tab-link-${category.id}`}
                  onSetActive={() => setCurrTab(index)}
                  smooth
                  spy
                  offset={-70}
                  duration={300}
                  to={category.id}
                >
                  <Tab label={category.name} wrapped />
                </Link>
              ))}
          </Tabs>
        </TabsWrapper>
        {homeData.categories
          .filter((c) => c.products.length > 0)
          .map((category) => (
            <section key={category.id} id={category.id}>
              <SectionHeader
                title={category.name}
                description={category.description}
                variant="primary"
                bottomDivider
              />
              <Grid
                container
                spacing={[2, 3, 4]}
                paddingTop="15px"
                paddingBottom="35px"
                rowSpacing="30px"
              >
                {applySearchQuery(category.products).map((product) => (
                  <Grid key={product.id} item xs={12} sm={6} md={4}>
                    <ProductCard
                      {...product}
                      onClick={() => handleOpenPreview(product)}
                      disabled={product.stock_amount <= 0}
                    />
                  </Grid>
                ))}
              </Grid>
            </section>
          ))}
      </Container>
      <WhatsappFabButton
        phone={homeData.company.whatsapp}
        bottomPosition={
          account.isAuthenticated || cart?.products?.length > 0 ? "70px" : "2vh"
        }
      />
      {checkOrdersPermission() && <MyOrdersResume />}
      <CartResume />
      <Outlet context={[productSelected, setProductSelected]} />
    </PageWrapper>
  );
}
