import axios from "axios";
import { getEnvironment } from "../utils/setup";

const env = getEnvironment();

const api = axios.create({
  baseURL:
    env === "production"
      ? "https://api.lojas.in/"
      : "https://homolog-api.lojas.in/",
});

export { api };
