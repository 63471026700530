import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ArrowBackRounded } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../services/api";
import { Button, CircularProgress, Container, Grid } from "@mui/material";
import useDevice from "../../hooks/useDevice";
import useAccount from "../../hooks/useAccount";
import { CartInterfaceAPI } from "../../interfaces/cart";
import { numberToBRL } from "../../utils/formatters";
import { useRecoilValue } from "recoil";
import { homeDataState } from "../../state/atoms/homeData";
import { TransitionUp } from "../Transition/Transition";

export default function OrderSuccess() {
  const { id } = useParams();
  const account = useAccount();
  const [order, setOrder] = useState<CartInterfaceAPI>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const homeData = useRecoilValue(homeDataState);

  const handleClose = () => {
    navigate(`../meus-pedidos`);
  };

  const fetchOrderDetails = (showLoading = false) => {
    setLoading(showLoading);

    if (id) {
      api
        .get<CartInterfaceAPI>(`/orders/${id}/show`, {
          headers: { Authorization: `Bearer ${account.token}` },
        })
        .then((resp) => {
          setOrder(resp.data);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchOrderDetails(true);
  }, [id]);

  return (
    <Dialog fullScreen open={!!id} TransitionComponent={TransitionUp}>
      <AppBar sx={{ position: "sticky" }}>
        <Container maxWidth={isMobile ? false : "lg"} disableGutters>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              size="large"
              aria-label="close"
            >
              <ArrowBackRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Confirmação
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      {loading ? (
        <Grid container justifyContent="center" marginTop="25vh">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={3}
          marginTop="5vh"
          paddingX={3}
        >
          <Grid item xs={12}>
            <Typography fontSize={24} textAlign="center" fontWeight="bold">
              Pedido enviado! 🎉
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography textAlign="center">
              <b id="orderNumber">{order?.name}</b> no valor de{" "}
              <b id="orderTotal">{numberToBRL(order?.total)}</b> foi enviado
              para loja. Você pode acompanhar o andamento e ver mais informações
              clicando no botão abaixo:
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Button
              onClick={() => navigate(`../pedido/${order?.id}`)}
              variant="outlined"
              size="large"
            >
              detalhes do pedido
            </Button>
          </Grid>
        </Grid>
      )}
    </Dialog>
  );
}
