import { useRecoilState } from "recoil";
import { accountState } from "../state/atoms/account";
import { AccountInterface } from "../interfaces/account";
import { OrderInterfaceAPI } from "../interfaces/order";

const useAccount = () => {
  const [account, setAccount] = useRecoilState(accountState);

  const setToken = (token: AccountInterface["token"]) => {
    setAccount((prev) => ({ ...prev, token }));
  };

  const setPreviousOrder = (order: OrderInterfaceAPI) => {
    setAccount((prev) => ({ ...prev, previousOrder: order }));
  };

  const isAuthenticated = !!account.token;

  return {
    ...account,
    isAuthenticated,
    setToken,
    setPreviousOrder,
  };
};

export default useAccount;
