import { Outlet, RouteObject } from "react-router-dom";
import Checkout from "./components/Checkout/Checkout";
import MyOrders from "./components/MyOrders/MyOrders";
import OrderDetails from "./components/OrderDetails/OrderDetails";
import ProductPreview from "./components/ProductPreview/ProductPreview";
import Home from "./pages/Home";
import Root from "./pages/Root";
import PixPayment from "./components/PixPayment/PixPayment";
import OrderSuccess from "./components/OrderSuccess/OrderSuccess";

const shopRoutes = [
  {
    path: "produto/:id",
    element: <ProductPreview />,
  },
  {
    path: "pedido/:id",
    element: <OrderDetails />,
  },
  {
    path: "sucesso/:id",
    element: <OrderSuccess />,
  },
  {
    path: "carrinho",
    element: <Checkout />,
  },
  {
    path: "meus-pedidos",
    element: <MyOrders />,
  },
];

const routesDefault: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Root />,
      },
      {
        path: ":slug",
        element: <Home />,
        children: shopRoutes,
      },
    ],
  },
];

const routesCustomDomain: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        path: "",
        element: <Home />,
        children: shopRoutes,
      },
    ],
  },
];

export const routes =
  window.location.hostname.includes("lojas.in") ||
  window.location.hostname.includes("localhost")
    ? routesDefault
    : routesCustomDomain;
