import { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { ArrowBackRounded } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { api } from "../../services/api";
import {
  CircularProgress,
  Container,
  Grid,
  Pagination as MuiPagination,
} from "@mui/material";
import { theme } from "../../styles/theme";
import { orderStatusAllMap } from "../../services/static";
import useDevice from "../../hooks/useDevice";
import { Pagination } from "../../interfaces/pagination";
import useAccount from "../../hooks/useAccount";
import { OrderResumeInterfaceAPI } from "../../interfaces/order";
import { TransitionUp } from "../Transition/Transition";

export default function MyOrders() {
  const navigate = useNavigate();
  const location = useLocation();
  const account = useAccount();
  const [results, setResults] =
    useState<Pagination<OrderResumeInterfaceAPI[]>>();
  const [currPage, setCurrPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const { isMobile } = useDevice();

  const isOpen = location.pathname.includes("/meus-pedidos");

  const handleClose = () => {
    navigate(`../`);
  };

  const handleOpenOrder = (id: string) => {
    navigate(`../pedido/${id}`);
  };

  const getStatusData = (id: string) => {
    return orderStatusAllMap.find((s) => s.id === id);
  };

  const handlePaginate = (e: React.ChangeEvent<unknown>, value: number) => {
    setCurrPage(value);
  };

  const fetchOrders = (showLoading: boolean) => {
    setLoading(showLoading);
    api
      .get<Pagination<OrderResumeInterfaceAPI[]>>(
        `/orders/${window.slug}/all?page=${currPage}`,
        {
          headers: { Authorization: `Bearer ${account.token}` },
        }
      )
      .then((resp) => {
        setResults(resp.data);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchOrders(true);
  }, [currPage]);

  return (
    <Dialog fullScreen open={isOpen} TransitionComponent={TransitionUp}>
      <AppBar sx={{ position: "sticky" }}>
        <Container maxWidth={isMobile ? false : "lg"} disableGutters>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              size="large"
              aria-label="close"
            >
              <ArrowBackRounded />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Meus pedidos
            </Typography>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth={isMobile ? false : "lg"} disableGutters>
        {loading ? (
          <Grid container justifyContent="center" marginTop="25vh">
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <>
            <List>
              {results?.data.map((order) => {
                const status = getStatusData(order.order_status_id);
                return (
                  <ListItem
                    key={order.id}
                    divider
                    style={{
                      justifyContent: "space-between",
                      paddingTop: "15px",
                      paddingBottom: "15px",
                    }}
                    onClick={() => handleOpenOrder(order.id)}
                  >
                    <div>
                      <Typography>{order.name}</Typography>
                    </div>
                    <Typography color={theme.tertiary}>
                      {order.created_at}
                    </Typography>
                  </ListItem>
                );
              })}
            </List>
            <Grid container justifyContent="center" paddingY={2}>
              <Grid item>
                <MuiPagination
                  count={results?.last_page}
                  page={currPage}
                  onChange={handlePaginate}
                  color="primary"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Container>
    </Dialog>
  );
}
