export const numberToBRL = (value?: number): string => {
  if (value === null || value === undefined) return "";
  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  });
};

export const removeStringAccents = (value: string) => {
  if (!value) return "";
  return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};

export const stringToBRL = (value: string) => {
  let newValue = value;
  const numberRegex = /\D/g;
  newValue = newValue.replace(numberRegex, "");
  newValue = `${newValue.slice(0, -2)},${newValue.slice(-2)}`;
  return newValue;
};

export const stringToPhone = (value: string) => {
  const numberRegex = /\D/g;
  let newValue = value.replace(numberRegex, "");

  let ddd = "";
  let firstPart = "";
  let lastPart = "";

  ddd = newValue.slice(0, 2);
  firstPart = newValue.slice(2, 7);
  lastPart = newValue.slice(7, 11);

  newValue = `(${ddd}) ${firstPart}-${lastPart}`;
  return newValue;
};

export const phoneToString = (value: string) => {
  const numberRegex = /\D/g;
  let newValue = value.replace(numberRegex, "");
  return newValue;
};

export const textPlural = (
  singularText: string,
  pluralText: string,
  count: number
) => {
  return count > 1 ? pluralText : singularText;
};
