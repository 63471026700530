import { DialogContent } from "@mui/material";
import { styled } from "styled-components";
import { theme } from "../../styles/theme";

export const CloseButton = styled.i`
  width: 32px;
  height: 32px;
  border-bottom-left-radius: 10px;
  background: ${theme.background};
  color: ${theme.text};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
`;

export const DialogContentStyled = styled(DialogContent)`
  padding: 0 !important;
  position: relative !important;
`;

export const Image = styled.div<{ src: string | null }>`
  width: 100%;
  height: auto;
  padding-top: 100%;
  background-color: ${theme.border};
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-size: cover;
`;

export const Title = styled.h2`
  font-size: 22px;
  color: ${theme.primary};
  margin-bottom: 10px;
`;

export const Description = styled.div`
  font-size: 16px;
  color: ${theme.text};
  font-weight: 300;
  margin: 15px 0 25px 0;
  white-space: pre-line;
`;

export const Price = styled.strong`
  font-size: 16px;
  color: ${theme.secondary};
`;

export const FullDescription = styled.div`
  color: ${theme.tertiary};
  margin-top: 25px;
`;
