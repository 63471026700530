import { createTheme } from "@mui/material/styles";
import { SettingsInterface } from "../interfaces/settings";

export const themeDefault = {
  primary: "#5a1cd6",
  secondary: "#8254de",
  tertiary: "#777777",
  border: "#F3F3F3",
  background: "#FFFFFF",
  document: "#F3F3F3",
  text: "#000000",
  success: "#54AE35",
  error: "#D82525",
  warning: "#FF8A00",
};

export const createThemeMUI = (themeColors: SettingsInterface["color"]) => {
  return createTheme({
    palette: {
      primary: {
        main: themeColors.primary,
      },
      secondary: {
        main: themeColors.secondary,
      },
      info: {
        main: themeColors.tertiary,
      },
    },
    typography: {
      fontFamily: "'Nunito', sans-serif",
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            color: "white",
          },
        },
      },
      MuiFab: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "normal",
            position: "fixed",
            right: "5vh",
            bottom: "5vh",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "14px",
            fontWeight: "700",
            lineHeight: "normal",
          },
          contained: {
            color: "white",
          },
          sizeLarge: {
            minHeight: "50px",
          },
        },
      },
      MuiListItemText: {
        styleOverrides: {
          root: {
            cursor: "pointer",
          },
        },
      },
      MuiBackdrop: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            background: themeColors.background,
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            whiteSpace: "nowrap",
            fontFamily: "inherit",
            fontSize: "inherit",
          },
        },
      },
    },
  });
};

export const createThemeVariablesCSS = (
  themeColors: SettingsInterface["color"]
) => {
  const rootCSS = `
    :root{ 
      --primary: ${themeColors.primary}; 
      --secondary: ${themeColors.secondary}; 
      --tertiary: ${themeColors.tertiary}; 
      --background: ${themeColors.background}; 
      --text: ${themeColors.text}; 
      --border: ${themeColors.border}; 
      --document: ${themeColors.document}; 
      --error: ${themeColors.error}; 
      --success: ${themeColors.success}; 
      --warning: ${themeColors.warning}; 
    }
  `;

  const styleTag = document.querySelector("#themeVariables");
  if (styleTag) styleTag.innerHTML = rootCSS;
};

export const themeDefaultCSSVariables = {
  primary: "var(--primary)",
  secondary: "var(--secondary)",
  tertiary: "var(--tertiary)",
  border: "var(--border)",
  background: "var(--background)",
  document: "var(--document)",
  text: "var(--text)",
  success: "var(--success)",
  error: "var(--error)",
  warning: "var(--warning)",
};

export const theme = themeDefaultCSSVariables;
