import { styled } from "styled-components";
import { theme } from "../../styles/theme";

export const CirclePulseWrapper = styled.div`
  color: ${theme.primary};
`;

export const IconStepWrapper = styled.div<{
  color: "primary" | "tertiary" | "error";
}>`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: ${({ color }) => theme[color]};
`;
