import { HomeDataInterface } from "../interfaces/home";

export const weekdayNumToNamePt = (weekdayNumber: number) => {
  const weekdayNames = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];

  return weekdayNames[
    weekdayNumber
  ] as keyof HomeDataInterface["company"]["opening_hours"];
};
