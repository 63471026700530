import { styled } from "styled-components";
import { theme } from "../../styles/theme";

export const Card = styled.div`
  width: 100%;
  position: relative;
  cursor: pointer;
`;

export const Image = styled.div<{ src: string | null; disabled: boolean }>`
  width: 80px;
  height: auto;
  padding-top: 100%;
  background-color: ${theme.border};
  background-image: url(${({ src }) => src});
  background-position: center center;
  background-size: cover;
  border-radius: 5px;
  opacity: ${({ disabled }) => (disabled ? "0.4" : "1.0")};
`;

export const Title = styled.h3<{ disabled: boolean }>`
  font-size: 16px;
  line-height: 18px;
  color: ${theme.text};
  color: ${({ disabled }) => (disabled ? theme.tertiary : theme.text)};
  margin: 0px 0px 4px 0px;
`;

export const Description = styled.p<{ disabled: boolean }>`
  color: ${({ disabled }) => (disabled ? theme.tertiary : theme.text)};
  margin-bottom: 4px;
  width: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 14px;
  line-height: 16px;
`;

export const Price = styled.strong<{ disabled: boolean }>`
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? theme.tertiary : theme.secondary)};
`;
