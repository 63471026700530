import { useRecoilValue } from "recoil";
import { FeatureSlug } from "../../interfaces/plan";
import { homeDataState } from "../../state/atoms/homeData";

const useCompanyFeatures = () => {
  const {
    company: { features },
  } = useRecoilValue(homeDataState);

  const hasFeature = (feature: FeatureSlug) => {
    return features.find(({ slug }) => slug === feature) !== undefined;
  };

  return {
    hasFeature,
  };
};

export default useCompanyFeatures;
