import { ProductCardProps } from "./ProductCardTypes";
import { Card, Description, Image, Price, Title } from "./ProductCardStyles";
import { numberToBRL } from "../../utils/formatters";
import { Chip, Grid } from "@mui/material";

function ProductCard({
  name,
  full_description,
  short_description,
  price,
  photo_path,
  disabled,
  onClick = () => {},
}: ProductCardProps) {
  return (
    <Card onClick={onClick}>
      <Grid container spacing={2}>
        <Grid item flex={0}>
          <Image src={photo_path} disabled={disabled} />
        </Grid>
        <Grid item flex={1}>
          <Title disabled={disabled}>{name}</Title>
          <Description disabled={disabled}>
            {short_description ?? full_description}
          </Description>
          <Grid container justifyContent="space-between" alignItems="center">
            <Price disabled={disabled}>{numberToBRL(price)}</Price>
            {disabled && (
              <Chip
                color="warning"
                label="sem estoque"
                size="small"
                style={{
                  fontWeight: "bold",
                  position: "absolute",
                  left: "0",
                  top: "calc(50% - 10px)",
                  borderRadius: 0,
                  borderTopRightRadius: 20,
                  borderBottomRightRadius: 20,
                }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}
export default ProductCard;
