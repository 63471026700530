import { useEffect } from "react";
import { getEnvironment } from "../utils/setup";

export default function Root() {
  useEffect(() => {
    const env = getEnvironment();
    if (env !== "development") {
      window.location.href = "https://muser.com.br";
    }
  }, []);

  return (
    <div style={{ padding: "20px" }}>
      <p>
        <b>Muser | Sistema para marmitarias</b>
        <br />
        Cardápio digital e gestão completa feito para marmitarias.
      </p>
      <p>
        Saiba mais em <a href="https://muserdelivery.com.br">muser.com.br</a>
      </p>
    </div>
  );
}
