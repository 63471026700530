import { atom } from "recoil";
import { localStorageEffect } from "../helper/localStorage";
import { AccountInterface } from "../../interfaces/account";

export const accountState = atom<AccountInterface>({
  key: "account",
  default: {
    token: "",
    previousOrder: null,
  },
  effects: [localStorageEffect("account")],
});
